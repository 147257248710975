/* eslint-disable no-underscore-dangle */
import React from 'react';
import Loadable from 'react-loadable';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  util, checkVersion, hasAuth, getNoAuthPath
} from 'common';

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.menuKeys = [];
    this.componentPathCache = {};
    this.obsFlag = window.user.obsFlag;
    this.ignorePath = [
      { path: '/farm', name: '矿场列表', },
      { path: '/network', name: '网络监控', },
      { path: '/miner-monitor/range-view', name: '设备视图详情', },
      { path: '/other/miner-view', name: '矿机后台页面' },
      { path: '/mbox-view/mbox', name: '矿箱详情', },
      { path: '/mbox-view/cabisets', name: '机组详情', },
      { path: '/nuc/table-list', name: 'iptable列表', noAuth: !hasAuth('priv-setting-server-management-iptables-delete') },
      { path: '/nuc/reported-list', name: '生效矿池白名单列表', noAuth: !hasAuth('priv-setting-server-management-iptables-delete') }
    ];
  }

  /*** 异常捕获 ***/
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error(errorInfo);
  }

  mapStateToProps = (state) => {
    const tempLocation = state.routing.location || {};
    return {
      farm: state.farm,
      routing: {
        pathname: tempLocation.pathname,
        params: util.queryToJson(tempLocation.search || ''),
      },
    };
  };

  /*** 去掉url中的中划线「-」，格式化为驼峰格式 ***/
  getComponentPath = (pathname) => {
    if (this.componentPathCache[pathname]) {
      return this.componentPathCache[pathname];
    }
    return this.componentPathCache[pathname] = pathname.replace(/^\//, '')
      .split('/')
      .map(word => word.split('-').map(util.capitalize).join(''))
      .join('/');
  };

  /*** 根据路径获取Component ***/
  getComponent = path => Loadable({
    loader: () => import(`pages/${path}`)
      .then(module => connect(this.mapStateToProps)(module && module.__esModule ? module.default : module.obj))
      .catch(() => { window.location.href = '/'; return 'error'; }),
    loading: () => (
      <div style={{
        textAlign: 'center',
        padding: '50px',
      }}><Spin size="large" /></div>
    ),
  });

  isAuthorizedPath = (menuKeys, path) => {
    const noAuthPath = getNoAuthPath(this.props.farm, this.obsFlag);
    const ignorePathUrl = this.ignorePath.filter(item => !item.noAuth).map(item => item.path);
    menuKeys = [...menuKeys, ...ignorePathUrl].filter(a => !noAuthPath.includes(a));
    return menuKeys.includes(path);
  };

  getMenuKeys = (menus) => {
    const menuKeys = [];
    this.loop(menus, (item) => {
      menuKeys.push(item.path);
    });
    return menuKeys;
  }

  // 根据path获取menuName
  getMenuName = (path) => {
    let menuName = '';
    this.loop(this.props.menus, (item) => {
      if (item.path === path) {
        menuName = item.menuName;
      }
    });
    //如果没有菜单名，使用在前端配置的菜单中获取
    if (!menuName) {
      menuName = this.ignorePath.find(item => item.path === path)?.name;
    }
    return menuName;
  }

  loop = (menus, callback) => {
    function flatten(list = []) {
      list.forEach((item, index, arr) => {
        if (item.path) {
          callback(item, index, arr);
        }
        if (item.children && item.children.length > 0) {
          flatten(item.children);
        }
      });
    }
    flatten(menus);
    return menus;
  }

  getFirstMenusPath = (menus, menuKeys) => {
    let firstPath = '';
    this.loop(menus, (item) => {
      if (item.path && this.isAuthorizedPath(menuKeys, item.path) && !firstPath) {
        firstPath = item.path;
      }
    });
    return firstPath;
  }

  render() {
    const { menus } = this.props;
    const menuKeys = this.getMenuKeys(menus);
    const firstPath = this.getFirstMenusPath(menus, menuKeys);
    return (
      <Switch>
        <Route render={(props) => {
          if (process.env.NODE_ENV !== 'development') {
            checkVersion();
          }
          let path = props.location.pathname;
          // 默认进入第一个有权限菜单页
          if (path === '/') { path = firstPath; }
          //hasAuth不需要权限验证的BlankLayout也是用这个routes
          if (!this.props.hasAuth && !this.isAuthorizedPath(menuKeys, path)) {
            window.location.href = `/#${firstPath}` || '/#/';
            return false;
          }
          window.sendEvent('page_view', {
            page_path: path,
            page_title: this.getMenuName(path),
            page_location: window.location.href,
          });
          const Component = this.getComponent(this.getComponentPath(path));

          return <Component {...props} />;
        }} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  farm: state.farm,
  menus: state.menu.menus,
});

export default withRouter(
  connect(mapStateToProps)(Routes)
);
